<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('externalUserIrrigation.sales_entry')}}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="month_id"
                      >
                        <template v-slot:label>
                          {{$t('pusti_mapping.month')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.month_id"
                          :options="monthList"
                          id="month_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="allocation_type_id"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerSales.allocationType')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.allocation_type_id"
                          :options="allocationTypeList"
                          id="allocation_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="invoice_no"
                      >
                        <template v-slot:label>
                          {{$t('externalUserIrrigation.invoice_no')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          v-model="search.invoice_no"
                          id="invoice_no"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="6">
                      <ValidationProvider name="From Date" vid="from_date">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="from_date"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input class="form-control"
                                v-model="search.from_date"
                                placeholder="Select Date"
                                id="from_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6">
                      <ValidationProvider name="To Date" vid="to_date">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="to_date"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input class="form-control"
                                v-model="search.to_date"
                                placeholder="Select Date"
                                id="to_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  <!-- <b-col lg="6" sm="12">
                    <ValidationProvider name="Fertilizer Name"  vid="fertilizer_id" rules="">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fertilizer_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('movement.fertilizerName')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.fertilizer_id"
                          :options="fertilizerNameList"
                          id="fertilizer_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col> -->
                  <b-col class="col-sm-2">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('externalUserIrrigation.sales_entry_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(sell_date)="data">
                                          {{ data.item.sell_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(fertilizer_qty)="data">
                                          {{ $n(data.item.fertilizer_qty, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(fertilizer_amount)="data">
                                          {{ $n(data.item.fertilizer_amount, { useGrouping: false }) }}
                                        </template>
                                        <!-- <template v-slot:cell(fiscal_year_bn)="data">
                                           {{ $n(data.item.fiscal_year_bn.split('-')[0], { useGrouping: false }) + '-' +  $n(data.item.fiscal_year_bn.split('-')[1], { useGrouping: false }) }}
                                        </template> -->
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.draft')}}</span>
                                            <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="details(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="data.item.status === 1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button variant=" iq-bg-info" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" :disabled="data.item.status == 2"><i class="far fa-check-square m-0"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('externalUserIrrigation.sales_entry_list') + ' ' + $t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('externalUserIrrigation.sales_entry_list') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="editItemId" :items="items"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { saleEntryList, saleEntryFinalSave } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            search: {
                invoice_no: '',
                fiscal_year_id: 0,
                month_id: 0,
                allocation_type_id: 0,
                sell_date: ''
                // fertilizer_id: 0
            },
            items: ''
        }
    },
    computed: {
        fiscalYearList: function () {
          const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
          const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
        monthList: function () {
          return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
        },
        allocationTypeList: function () {
            const type = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.filter(item => item.status === 1)
            const tempData = type.map(item => {
              if (this.$i18n.locale === 'bn') {
                return { text: item.text_bn, value: item.value }
              } else {
                return { text: item.text_en, value: item.value }
              }
            })
            return tempData
        },
        fertilizerNameList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList
        },
        formTitle () {
            return this.editItemId === 0 ? this.$t('externalUserIrrigation.sales_entry') : this.$t('externalUserIrrigation.sales_entry') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
                { label: this.$t('fertilizerSales.allocationType'), class: 'text-left' },
                { label: this.$t('pusti_mapping.month'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.sell_date'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.invoice_no'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-left' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                  { key: 'index' },
                  { key: 'fiscal_year_bn' },
                  { key: 'allocation_type_name_bn' },
                  { key: 'month_name_bn' },
                  { key: 'sell_date' },
                  { key: 'invoice_no' },
                  { key: 'status' },
                  { key: 'action' }
                ]
            } else {
                keys = [
                  { key: 'index' },
                  { key: 'fiscal_year' },
                  { key: 'allocation_type_name' },
                  { key: 'month_name' },
                  { key: 'sell_date' },
                  { key: 'invoice_no' },
                  { key: 'status' },
                  { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
      flatpickr('#from_date', {})
      flatpickr('#to_date', {})
      core.index()
    },
    methods: {
      changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        finalSave (item) {
          this.toggleStatusCustom2(seedFertilizerServiceBaseUrl, saleEntryFinalSave, item)
        },
        details (item) {
          this.items = item
        },
        // remove (item) {
        //     this.toggleStatusCustom(seedFertilizerServiceBaseUrl, saleEntryStatus, item)
        // },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, saleEntryList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
          const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
          const allocationTypeList = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList
          const monthList = this.monthList
          const listData = data.map(item => {
            const fiscalYearObj = fiscalYears.find(doc => doc.value === item.fiscal_year_id)
            const allocationTypeObj = allocationTypeList.find(data => data.value === item.allocation_type_id)
            const monthObj = monthList.find(data => data.value === item.month_id)
            const fiscalYearData = {}
            if (fiscalYearObj !== undefined) {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
            const allocationTypeData = {}
            if (allocationTypeObj !== undefined) {
              allocationTypeData.allocation_type_name = allocationTypeObj.text_en
              allocationTypeData.allocation_type_name_bn = allocationTypeObj.text_bn
            } else {
              allocationTypeData.allocation_type_name = ''
              allocationTypeData.allocation_type_name_bn = ''
            }
            const monthData = {}
            if(monthObj !== undefined) {
              monthData.month_name = monthObj.text_en
              monthData.month_name_bn = monthObj.text_bn
            } else {
              monthData.month_name = ''
              monthData.month_name_bn = ''
            }

            return Object.assign({}, item, fiscalYearData, allocationTypeData, monthData)
          })
          return listData
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
