<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col sm="12" class="form-for-textarea">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" enctype="multipart/form-data">
                    <b-row>
                      <b-col sm="3">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                @change="getAllocationStockInfo"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="3">
                          <ValidationProvider name="month"  vid="month_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pusti_mapping.month')}} <span class="text-danger">*</span>
                              </template>
                               <b-form-select
                                plain
                                v-model="formData.month_id"
                                :options="monthList"
                                @change="getAllocationStockInfo"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="3">
                          <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="allocation_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.allocation_type_id"
                                :options="allocationTypeList"
                                @change="getAllocationStockInfo"
                                id="allocation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="3">
                          <ValidationProvider name="Date" vid="sell_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="sell_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.date')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="date-picker"
                                  v-model="formData.sell_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="6" sm="12">
                          <ValidationProvider name="invoice no" vid="invoice_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="invoice_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('externalUserIrrigation.invoice_no')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                v-model="formData.invoice_no"
                                id="invoice_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fertilizer Name" vid="fertilizer_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.fertilizerName')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fertilizer_id"
                                :options="fertilizerNameList"
                                id="fertilizer_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="id ? true: false"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fertilizer Quantity" vid="fertilizer_qty" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_qty"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('externalUserIrrigation.fertilizer_qty')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                type="number"
                                v-model="formData.fertilizer_qty"
                                id="fertilizer_qty"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fertilizer Amount" vid="fertilizer_amount" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('externalUserIrrigation.fertilizer_amount')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                v-model="formData.fertilizer_amount"
                                id="fertilizer_amount"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col> -->
                    </b-row>
                    <b-table-simple class="mt-3 text-center" hover bordered small caption-top responsive>
                      <b-thead head-variant="secondary">
                        <b-tr>
                          <b-th colspan="7" class="text-right text-danger"><span>{{ stockMsg }}</span></b-th>
                        </b-tr>
                        <b-tr>
                          <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th>{{ $t('fertilizerConfig.fertilizer_name') }}</b-th>
                          <b-th>{{ $t('externalUserIrrigation.allotment_amount') }}</b-th>
                          <b-th>{{ $t('dealer_panel.delivery_qty') }}</b-th>
                          <b-th>{{ $t('dealer_panel.sale_qty') }}</b-th>
                          <b-th>{{ $t('externalUserIrrigation.stock_available') }}</b-th>
                          <b-th>{{ $t('externalUserIrrigation.fertilizer_qty') }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(portInfoItem, index) in formData.sell_details" :key="index">
                          <b-td>{{ $n(index+1) }}</b-td>
                          <b-td>{{ currentLocale === 'en' ? portInfoItem.fertilizer_name : portInfoItem.fertilizer_name_bn }}</b-td>
                          <b-td>{{ $n(portInfoItem.allocation_qty) }}</b-td>
                          <b-td>{{ $n(portInfoItem.delivery_qty) }}</b-td>
                          <b-td>{{ $n(portInfoItem.sell_available_qty) }}</b-td>
                          <b-td>{{ $n(portInfoItem.stock_qty) }}</b-td>
                          <b-td class="text-right">
                            <b-form-group
                            >
                              <b-form-input
                                  :id="'fertilizer_qty_'+(index+1)"
                                  :ref="'fertilizer_qty_'+(index+1)"
                                  type="number"
                                  step="0.01"
                                  v-model="portInfoItem.quantity"
                              ></b-form-input>
                            </b-form-group>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.form-for-textarea textarea{ line-height: 18px !important;height: 54px !important; }
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { saleEntryStore, saleEntryUpdate, allocationStockInfo } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.sell_details = []
      const fertilizerDetailsInfo = tmp.dealer_sell_details
      this.allocaitonStockInfo.filter(item => {
        const fertiDetailsItem = fertilizerDetailsInfo.find(portDetailsObj => portDetailsObj.fertilizer_id === item.fertilizer_id)
        const portInfoItem = {
          sell_details_id: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.id : 0,
          fertilizer_id: item.fertilizer_id,
          quantity: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.quantity : '',
          allocation_qty: item.allocation_qty,
          sell_available_qty: item.sell_available_qty,
          fertilizer_name: item.fertilizer_name,
          fertilizer_name_bn: item.fertilizer_name_bn
        }
        this.formData.sell_details.push(portInfoItem)
      })
    }
    this.getAllocationStockInfo()
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      stockMsg: '',
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        sell_date: '',
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month_id: 0,
        sell_details: []
        // fertilizer_id: 0,
        // fertilizer_qty: '',
        // fertilizer_amount: ''
      },
      allocaitonStockInfo: []
    }
  },
  computed: {
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    monthList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
    },
    allocationTypeList: function () {
        const type = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    fertilizerNameList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    // 'formData.month_id': function (newVal, oldVal) {
    //     this.allocaitonStockInfo = this.getAllocationStockInfo(newVal)
    // },
    // 'formData.allocation_type_id': function (newVal, oldVal) {
    //     this.allocaitonStockInfo = this.getAllocationStockInfo(newVal)
    // }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${saleEntryUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, saleEntryStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.stockMsg = result.message
        this.errorLists = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    },
    async getAllocationStockInfo () {
      this.formData.sell_details = []
      let result = []
      if (this.formData.allocation_type_id !== 0) {
        result = await RestApi.getData(seedFertilizerServiceBaseUrl, allocationStockInfo, this.formData)
      }
      if (result.success) {
        this.allocaitonStockInfo = this.getRelationalData(result.data)
        if (this.id) {
          const tmp = this.getFormData()
          this.formData = tmp
          const fertilizerDetailsInfo = tmp.dealer_sell_details
          const checkData = []
          this.allocaitonStockInfo.filter(item => {
            const fertiDetailsItem = fertilizerDetailsInfo.find(portDetailsObj => portDetailsObj.fertilizer_id === item.fertilizer_id)
            const portInfoItem = {
              sell_details_id: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.id : 0,
              fertilizer_id: item.fertilizer_id,
              quantity: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.quantity : '',
              allocation_qty: item.allocation_qty,
              sell_available_qty: item.sell_available_qty,
              stock_qty: item.stock_qty,
              delivery_qty: item.delivery_qty,
              fertilizer_name: item.fertilizer_name,
              fertilizer_name_bn: item.fertilizer_name_bn
            }
            checkData.push(portInfoItem)
          })
          this.formData.sell_details = checkData
        } else {
          this.allocaitonStockInfo.filter(item => {
            const portInfoItem = {
              fertilizer_id: item.fertilizer_id,
              quantity: '',
              allocation_qty: item.allocation_qty,
              sell_available_qty: item.sell_available_qty,
              fertilizer_name: item.fertilizer_name,
              stock_qty: item.stock_qty,
              delivery_qty: item.delivery_qty,
              fertilizer_name_bn: item.fertilizer_name_bn
            }
            this.formData.sell_details.push(portInfoItem)
          })
        }
      } else {
        this.allocaitonStockInfo = []
      }
    },
    getRelationalData (data) {
      return data.map(item => {
        const sellQty = item.sell_qty
        const availableQuantities = {
          sell_available_qty: sellQty
        }
        return Object.assign({}, item, availableQuantities)
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getValidity (event) {
      var total = 0
      this.formData.dealerInfo.forEach((dealer, index) => {
        if (dealer.quantity) {
          total += parseInt(dealer.quantity)
        }
      })
      this.remaining = this.badc_allotment - total
      if (this.remaining < 0) {
        const currentId = '#' + event.target.id
        const idValAmount = document.querySelector(currentId).value
        const remainingIdVal = Math.floor(idValAmount / 10)
        document.querySelector(currentId).value = remainingIdVal
        this.remaining = this.badc_allotment - ((total - idValAmount) + remainingIdVal)
      }
    }
  }
}
</script>
